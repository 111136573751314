import getData from "@/__main__/get-data.mjs";
import { MapRE } from "@/game-eft/constants/constants.mjs";
import { getLatestMapVersion } from "@/game-eft/constants/map-data.mjs";
import { MapMarkerValidate } from "@/game-eft/models/static.mjs";
import * as API from "@/game-eft/utils/api.mjs";

export default async function fetchData(
  _params: never[],
  searchParams: URLSearchParams,
) {
  const mapName = searchParams.get("mapName") ?? MapRE.bigmap;

  const mapVersion = getLatestMapVersion(mapName, true);

  const mapMarkers = await getData(
    API.getMapMarkers(mapName),
    MapMarkerValidate,
    ["eft", "maps", "markers", mapName, mapVersion],
  );

  return mapMarkers;
}
